import dynamic from 'next/dynamic';
import Router from 'next/router';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
// constants
import constants from '@/constants';
const BODY_CONTAINER_ID = 'BODY_CONTAINER';
const isPartnerPlatform = constants.platform === 'partner';
// components
import Footer from '@/components/shared/footer';
import MenuBar from '@/components/shared/menuBar';
import Empty from '@/components/widgets/Empty';
import ScrollToId from '@/components/widgets/scrollToWidget/scrollToId';
const ExitIntentModal = dynamic(
	() => import('@/components/widgets/exitIntentModal'),
	{ ssr: false, loading: Empty }
);
const AnnouncementBar = dynamic(
	() => import('@/components/shared/announcementBar'),
	{ ssr: false, loading: Empty }
);
const ScrollToForm = dynamic(
	() => import('@/components/widgets/scrollToWidget/scrollToForm'),
	{ ssr: false, loading: Empty }
);
let WhatsAppChatBot = Empty,
	LoginSignupModal = Empty,
	StickyLoginWidget = Empty;

if (!isPartnerPlatform) {
	WhatsAppChatBot = dynamic(
		() => import('@/components/widgets/whatsAppChatBot'),
		{ ssr: false, loading: Empty }
	);

	LoginSignupModal = dynamic(
		() => import('@/components/widgets/loginSignupModal'),
		{ ssr: false, loading: Empty }
	);
	StickyLoginWidget = dynamic(
		() => import('@/components/widgets/stickyLoginWidget'),
		{ ssr: false, loading: Empty }
	);
}

// mui
import Box from '@mui/material/Box';
//utils
import useAnnouncement from '@/utils/hooks/use-announcement';
import useFloatingButtons from '@/utils/hooks/use-floating-buttons';
import useStickyLoginWidget from '@/utils/hooks/use-sticky-login-widget';
import { LazyFooter } from '@/utils/withLazyLoad';

// styles
import useStyles from './style';
export default function Layout({
	showBackgroundImage,
	resolvedUrl,
	pageFormId,
	formButtonText,
	hasDynamicZone,
	children,
}) {
	const { classes } = useStyles({ showBackgroundImage });
	const isMobile = useSelector((state) => state.common.isMobile);
	const menuBarProps = useSelector((state) => state.menuBar.data);
	const footerProps = useSelector((state) => state.footer.data);
	const exitIntentModalProps = useSelector(
		(state) => state.exitIntentModal.data
	);

	const [showFloatingButtonConfig, computeShowFloatingButtons] =
		useFloatingButtons(resolvedUrl);
	const [announcementConfig, computeShowAnnouncement] =
		useAnnouncement(resolvedUrl);
	const [stickyLoginWidgetConfig, computeShowStickyLoginWidget] =
		useStickyLoginWidget(resolvedUrl);

	const [loading, setLoading] = React.useState(false);

	const [widgetPositions, updateWidgetPositions] = React.useState(() => {
		const config = { whatsAppWidget: 1, scrollWidget: 10 };
		if (showFloatingButtonConfig.scroll && !showFloatingButtonConfig.chatbot) {
			config.scrollWidget = config.whatsAppWidget;
		}

		return config;
	});

	const setWidgetPositions = (config) => {
		if (showFloatingButtonConfig.scroll && !showFloatingButtonConfig.chatbot) {
			config.scrollWidget = config.whatsAppWidget;
		}
		updateWidgetPositions(config);
	};

	const StickWidget = React.useMemo(() => {
		if (!isMobile) return null;

		if (stickyLoginWidgetConfig.pathMatched) {
			return stickyLoginWidgetConfig.show ? (
				<StickyLoginWidget
					hideOnId={BODY_CONTAINER_ID}
					onOtpSent={() => {
						setWidgetPositions({ whatsAppWidget: 33, scrollWidget: 42 });
					}}
					onClose={() => {
						setWidgetPositions({ whatsAppWidget: 19, scrollWidget: 28 });
					}}
					onHide={() => {
						setWidgetPositions({ whatsAppWidget: 1, scrollWidget: 10 });
					}}
					onShow={(otpSent) => {
						otpSent
							? setWidgetPositions({ whatsAppWidget: 33, scrollWidget: 42 })
							: setWidgetPositions({ whatsAppWidget: 21, scrollWidget: 30 });
					}}
				/>
			) : null;
		} else if (pageFormId) {
			return (
				<ScrollToForm
					key={Date.now().toString()}
					scrollToId={pageFormId}
					buttonText={formButtonText}
					offset={-80}
					onHide={() => {
						setWidgetPositions({ whatsAppWidget: 1, scrollWidget: 10 });
					}}
					onShow={() => {
						setWidgetPositions({ whatsAppWidget: 10, scrollWidget: 19 });
					}}
					analyticsProps={{
						section: 'FLOATING_SECTION',
						eventName: 'CTA_BUTTON_CLICKED',
					}}
				/>
			);
		}
		return null;
	}, [
		isMobile,
		stickyLoginWidgetConfig,
		pageFormId,
		formButtonText,
		resolvedUrl,
	]);

	Router.onRouteChangeStart = () => {
		setLoading(true);
	};
	Router.onRouteChangeComplete = (path) => {
		computeShowFloatingButtons(path);
		computeShowAnnouncement(path);
		computeShowStickyLoginWidget(path);
		setLoading(false);
	};
	Router.onRouteChangeError = () => {
		setLoading(false);
	};
	return (
		<>
			{menuBarProps && (
				<MenuBar
					logo={menuBarProps.logo}
					actions={menuBarProps.actions}
					contactDetails={menuBarProps.contactDetails}
					buttonAction={menuBarProps.buttonAction}
					loading={loading}
				/>
			)}

			<Box className={classes.bodyContainer} id={BODY_CONTAINER_ID}>
				{announcementConfig.show && announcementConfig.data.length > 0 && (
					<AnnouncementBar data={announcementConfig.data} />
				)}
				{children}
			</Box>

			{footerProps && (
				<Box className={classes.footerContainer}>
					<LazyFooter
						Component={Footer}
						logo={footerProps.logo}
						actions={footerProps.actions}
						socialIconActions={footerProps.socialIconActions}
						androidAppIcon={footerProps.androidAppIcon}
						iosAppIcon={footerProps.iosAppIcon}
						footerAppIconLabel={footerProps.footerAppIconLabel}
						footerCopyRight={footerProps.footerCopyRight}
						iconButton={footerProps.iconButton}
						lazyLoad={hasDynamicZone}
						imageList={footerProps.imageList}
						imageShape={footerProps.imageShape}
						imageSize={footerProps.imageSize}
					/>
				</Box>
			)}

			<LoginSignupModal />

			{StickWidget}

			{showFloatingButtonConfig.scroll && (
				<ScrollToId
					scrollToId={BODY_CONTAINER_ID}
					triggerThreshold={1000}
					widgetPosition={widgetPositions.scrollWidget}
				/>
			)}

			{showFloatingButtonConfig.chatbot && (
				<WhatsAppChatBot widgetPosition={widgetPositions.whatsAppWidget} />
			)}

			{exitIntentModalProps && (
				<ExitIntentModal
					title={exitIntentModalProps.title}
					description={exitIntentModalProps.description}
					genericButton={exitIntentModalProps.button}
					openFormModal={exitIntentModalProps.openFormModal}
					openLoginModal={exitIntentModalProps.openLoginModal}
					form={exitIntentModalProps.form}
					body={exitIntentModalProps.body}
					timeout={exitIntentModalProps.timeToShowInSeconds * 1000}
				/>
			)}
		</>
	);
}
Layout.propTypes = {
	showBackgroundImage: PropTypes.bool,
	formButtonText: PropTypes.string,
	pageFormId: PropTypes.string,
	resolvedUrl: PropTypes.string.isRequired,
	hasDynamicZone: PropTypes.bool,
};
