import isEmpty from 'lodash/isEmpty';

import { fetchRegisteredEvents } from '@/api';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	loading: true,
	registeredEvents: null,
};

export const profile = createSlice({
	name: 'event',
	initialState,
	reducers: {
		setLoading: (state, { payload }) => {
			state.loading = payload;
		},
		setRegisteredEvents: {
			reducer: (state, { payload }) => {
				state.registeredEvents = payload;
			},
			prepare: (data) => {
				let payload = {};

				if (!isEmpty(data.meetings)) {
					payload = data.meetings.reduce((meets, meet) => {
						meets[meet.cms_event_id] = meet;
						return meets;
					}, {});
				}

				return { payload };
			},
		},

		resetData: (state) => {
			state.loading = false;
			state.registeredEvents = null;
		},
	},
});

export const { setLoading, setRegisteredEvents, resetData } = profile.actions;

const registeredEventsGetAndStore = () => (dispatch) => {
	fetchRegisteredEvents()
		.then((res) => {
			dispatch(setRegisteredEvents(res.data));
		})
		.catch(() => {
			dispatch(setRegisteredEvents({}));
		})
		.finally(() => dispatch(setLoading(false)));
};

export default profile;
export { registeredEventsGetAndStore };
