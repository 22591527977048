import eventInstance from './axios-instances/events';

export function fetchRegisteredEvents(query) {
	const search = query ? new URLSearchParams(query) : null;
	return eventInstance.get(
		search
			? `/registrant/events-registered?${search.toString()}`
			: '/registrant/events-registered'
	);
}
